import { useState, useEffect } from "react";
import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CategoryBox, AnchoredPopover, BigPopoverLayout, FoodsIcon } from "@foodpilot/foods";

import { Tag, useTags } from "src/api/client-api/useTags";
import { ProductFormTags, ProductFormType } from "src/pages/Product/ProductForm/ProductForm.types";

export type CategoryFieldProps = {
    tiles: string[];
    addAction: (newProduct: ProductFormType) => void;
    product: ProductFormType;
};
export const CategoryField = (props: CategoryFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const { tiles, addAction, product } = props;

    const [tempTags, setTempTags] = useState(product.tags);
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
    const isPopoverOpen = Boolean(popoverAnchor);

    useEffect(() => {
        setTempTags(product.tags);
    }, [product.tags]);

    function toggleCategorie(categorie: Tag, checked: boolean) {
        if (tempTags === null) {
            return;
        }
        if (checked === true) {
            setTempTags([...tempTags, categorie]);
        } else {
            const filteredTags = tempTags.filter((item) => item["@id"] !== categorie["@id"]);
            setTempTags(filteredTags);
        }
    }

    const { tags: availableCategories, isLoading, isError } = useTags();

    if (isLoading) {
        return <div>{t("word.loading-wait")}</div>;
    }

    if (isError) {
        return <div>{t("Une erreur est survenue")}</div>;
    }

    const onClose = () => {
        setPopoverAnchor(null);
        setTempTags(product.tags);
    };
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
            }}
        >
            <Typography variant="h5">{t("Catégories")} :</Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",

                    gap: "0.5rem",
                }}
            >
                {tiles.length === 0 && <Typography variant="body">{t("product.no-tags")}</Typography>}
                {tiles.map((tile) => {
                    return (
                        <CategoryBox
                            key={`product-tile-${tile}`}
                            text={tile}
                            action={() => {
                                const newTags = product.tags?.filter((tag) => tag.name !== tile);
                                addAction({
                                    ...product,
                                    tags: newTags ?? null,
                                });
                            }}
                        />
                    );
                })}
            </div>
            <AnchoredPopover
                open={isPopoverOpen}
                anchor={popoverAnchor}
                onClose={onClose}
                anchorPosition="leftside"
                variant="headless"
            >
                <BigPopoverLayout
                    headerText={t("Ajouter ou retirer des catégories")}
                    onClose={onClose}
                    onCancel={onClose}
                    onValidate={() => {
                        addAction({
                            ...product,
                            tags: tempTags,
                        });
                        setPopoverAnchor(null);
                    }}
                    bottomActions
                >
                    <CategoriesList
                        availableCategories={availableCategories}
                        tags={tempTags ?? []}
                        toggleCategorie={toggleCategorie}
                    />
                </BigPopoverLayout>
            </AnchoredPopover>
            <Button
                id="add-category-anchor"
                variant="text"
                startIcon={<FoodsIcon icon="plus" size={2} />}
                onClick={(e) => setPopoverAnchor(e.currentTarget)}
                style={{ alignSelf: "start" }}
            >
                {t("Ajouter ou retirer des catégories")}
            </Button>
        </div>
    );
};

interface ICategoriesListProps {
    availableCategories: Tag[] | undefined;
    tags: ProductFormTags[];
    toggleCategorie: (categorie: Tag, checked: boolean) => void;
}
function CategoriesList(props: ICategoriesListProps) {
    const { t } = useTranslation();
    const { tags } = props;

    if (props.availableCategories === undefined || props.availableCategories.length === 0) {
        return <div>{t("Aucune categorie disponible")}</div>;
    } else {
        return props.availableCategories.map((cat, index) => {
            const checked = tags?.map((tag) => tag["@id"]).includes(cat["@id"]);
            return (
                <Stack key={index} direction="row" alignItems="center">
                    <Checkbox checked={checked} onChange={(_e, checked) => props.toggleCategorie(cat, checked)} />
                    {cat.name}
                </Stack>
            );
        });
    }
}
