import { Button, DottedBox, Typography } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

import PlusIcon from "src/assets/icons/yellow-plus.svg?react";

type NoSuppliersProps = {
    openModal: () => void;
};
export const NoSuppliers = (props: NoSuppliersProps) => {
    const { t } = useTranslation();
    return (
        <DottedBox>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2rem",
                }}
            >
                <Typography variant="big-medium">{"Aucun fournisseur ajouté"}</Typography>
                <Button variant="text" startIcon={<PlusIcon />} onClick={props.openModal}>
                    {t("Ajouter des fournisseurs")}
                </Button>
            </div>
        </DottedBox>
    );
};
