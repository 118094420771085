import { useRef, useState } from "react";
import { CoordinatePopover, ScatterPlot, SelectedPoint, WhiteBox } from "@foodpilot/foods";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { VisualisationChoice } from "../MultipleSupplierScores";
import { Ingredient, IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";
import { ScatterHeader } from "./ScatterHeader";
import { ScatterBox } from "./ScatterBox";
import {
    ScatterScoreBoxCorePopover,
    ScatterScoreBoxCoreProps,
} from "src/pages/Ingredient/IngredientDetails/IngredientScores/MultipleSupplierScores/ScatterScoreBox/ScatterScoreBoxCorePopover.tsx";
import { Score as SureScore } from "src/types";
import { Score, EmptyScore, ScoreDimension, ScoreDimensionBaseValue } from "src/api/client-api/post/scoreSchema";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";

export type SupplierScatterPlotProps = {
    score: SureScore;
    ingredient: Ingredient;
    suppliers: IngredientSupplier[] | undefined;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
    referenceScore: ReferenceScore | null;
};

export const SupplierScatterPlot = (props: SupplierScatterPlotProps) => {
    const { t } = useTranslation();
    const { score, ingredient, suppliers, variations, setVariations, referenceScore, isRefetching = false } = props;

    const [selectedPoint, setSelectedPoint] = useState<SelectedPoint | null>(null);
    const [selectedVisualisationChoices, setVisualisationChoices] = useState(0);
    const [open, setOpen] = useState(true);

    const scatterRef = useRef<HTMLDivElement>(null);
    const ingredientScore = ingredient.scores[score.id];

    const visualisationChoices: VisualisationChoice[] = [
        { key: "quantity", unit: "T", label: t("Quantités achetées") },
        { key: "grossSales", unit: "€", label: t("CA pour cet ingrédient") },
    ];

    const buttonChoices = visualisationChoices.map((v) => ({
        id: visualisationChoices.indexOf(v),
        label: v.label,
        onClick: () => setVisualisationChoices(visualisationChoices.indexOf(v)),
    }));

    const currentDataset = getCurrentDataset(
        ingredientScore,
        suppliers,
        visualisationChoices[selectedVisualisationChoices],
    );

    const ScatterPlotIcon = !isRefetching ? EmptySetIcon : CircularProgress;

    const onPointSelected = (selectedPoint: SelectedPoint | null) => {
        if (scatterRef.current && selectedPoint) {
            const element = scatterRef.current;
            const { x, y } = element.getBoundingClientRect();
            const offsetSelectedPoint = {
                ...selectedPoint,
                absoluteCoordinate: {
                    y: selectedPoint!.absoluteCoordinate.y + y,
                    x: selectedPoint!.absoluteCoordinate.x + x,
                },
            };

            setSelectedPoint(offsetSelectedPoint);
            setOpen(!!offsetSelectedPoint);
        }
    };

    return (
        <WhiteBox sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
            <ScatterHeader
                score={score}
                selectedVisualisationChoice={selectedVisualisationChoices}
                setVisualisationChoices={setVisualisationChoices}
                buttonChoices={buttonChoices}
                variations={variations}
                setVariations={setVariations}
            />
            <Stack direction="row" gap="1rem" padding="1rem">
                <ScatterBox
                    title={t("En moyenne :")}
                    score={ingredient.scores[score.id]}
                    sureScore={score}
                    isRefetching={isRefetching}
                    referenceScore={referenceScore}
                    prevScore={ingredient?.prevScores?.[score.id]}
                    prevCampaignName={ingredient?.prevCampaignName}
                    variations={variations}
                />
                <Box ref={scatterRef} sx={{ flex: 2, position: "relative" }}>
                    <ScatterPlot
                        onPointSelection={onPointSelected}
                        values={!isRefetching ? currentDataset : []}
                        x={
                            <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                <Typography variant="h4">
                                    {visualisationChoices[selectedVisualisationChoices].label}
                                </Typography>
                                <Typography variant="body">{"-"}</Typography>
                                <Typography variant="body">
                                    {visualisationChoices[selectedVisualisationChoices].unit}
                                </Typography>
                            </Box>
                        }
                        y={
                            <Box sx={{ display: "flex", alignItems: "center", gap: ".5rem" }}>
                                <Typography variant="h4">{score.title}</Typography>{" "}
                                <Typography variant="body">{"-"}</Typography>
                                <Typography variant="body">{score.unit?.abbreviation}</Typography>
                            </Box>
                        }
                    />

                    {(isRefetching || currentDataset.length === 0) && (
                        <ScatterPlotIcon
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        />
                    )}

                    {selectedPoint?.absoluteCoordinate.x &&
                        selectedPoint?.absoluteCoordinate.y &&
                        currentDataset.length && (
                            <CoordinatePopover
                                {...selectedPoint.absoluteCoordinate}
                                open={open}
                                setOpen={setOpen}
                                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
                                content={
                                    <ScatterScoreBoxCorePopover
                                        {...selectedPointAsScoreBoxProps(selectedPoint, ingredientScore)}
                                    />
                                }
                            />
                        )}
                </Box>
            </Stack>
        </WhiteBox>
    );
};

const getCurrentDataset = (
    score: Score | EmptyScore,
    suppliers: IngredientSupplier[] | undefined,
    visualisationChoices: VisualisationChoice,
) => {
    if (!("dimensions" in score)) return [];

    const dimensions = score.dimensions;
    const dimension = dimensions.find((dimension: ScoreDimension) => dimension.label === "suppliers");

    if (!dimension) return [];

    return Object.entries(dimension.values).map(([id, value]: [string, ScoreDimensionBaseValue]) => {
        const companyId = Number(id);
        const currentCompanyData = suppliers?.find((s) => s.supplier.id === companyId);

        return {
            id: companyId,
            label: value.label,
            x: currentCompanyData?.[visualisationChoices.key] || 0,
            y: value.score,
        };
    });
};

const selectedPointAsScoreBoxProps = (point: SelectedPoint, score: Score | EmptyScore): ScatterScoreBoxCoreProps => {
    const { id, label } = point.point;

    const output: ScatterScoreBoxCoreProps = { title: label, score: score };

    if (!id || !("dimensions" in score)) return output;

    const dimensions = score.dimensions;
    const dimension = dimensions.find((dimension: ScoreDimension) => dimension.label === "suppliers");

    if (!dimension) return output;

    output.value = dimension.values[id];

    return output;
};
