import { Stack } from "@mui/material";
import { IngredientFormType } from "../../IngredientForm.types";
import { GenericAccordion } from "@foodpilot/foods";
import { InfoOutlined } from "@mui/icons-material";
import MediumForklift from "src/assets/icons/forklift-medium.svg?react";

import { Button, useFormBaseContext } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { CreateOutlined } from "@mui/icons-material";
import { InformationSummary } from "src/components/IngredientSummary/InformationSummary";
import { SupplierSummary } from "src/components/IngredientSummary/SupplierSummary";

type ModifyButtonProps = {
    pageNumber: number;
};
export const ModifyButton = (props: ModifyButtonProps) => {
    const { setPage } = useFormBaseContext();
    const { t } = useTranslation();

    const modifyText = t("Modifier");
    return (
        <Button
            variant="white-filled"
            startIcon={<CreateOutlined />}
            sx={{ marginRight: "24px", borderRadius: "44px" }}
            onClick={() => setPage(props.pageNumber)}
        >
            {modifyText}
        </Button>
    );
};

type Page3Props = {
    ingredient: IngredientFormType;
};

const Page3 = (props: Page3Props) => {
    const { ingredient } = props;

    return (
        <div>
            <Stack direction="column" useFlexGap gap="8px">
                <GenericAccordion
                    headerIcon={<InfoOutlined />}
                    headerText="Informations générales"
                    controls={<ModifyButton pageNumber={0} />}
                >
                    <InformationSummary ingredient={ingredient} />
                </GenericAccordion>

                <GenericAccordion
                    headerIcon={<MediumForklift />}
                    headerText="Fournisseurs"
                    controls={<ModifyButton pageNumber={1} />}
                >
                    <SupplierSummary ingredientSuppliers={ingredient.ingredientSuppliers} />
                </GenericAccordion>
            </Stack>
        </div>
    );
};
export default Page3;
