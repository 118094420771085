import { ChapterName, isChapterName, FormattedScores } from "../../types";
import { Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Stack, Typography, useTheme } from "@mui/material";
import { DoughnutChart, DoughnutChartProps } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils/formatting";
import { getFormattedScoreByScoreId } from "src/utils/ssq";

type ScoresChartProps = DoughnutChartProps & {
    formattedScores?: FormattedScores;
    headings?: Heading[];
    displayedScoreId?: number;
    displayedVariationId?: number;
    displayMode?: ChapterName;
    precision?: number;
};

export const ScoresChart = (props: ScoresChartProps) => {
    const {
        formattedScores = {},
        displayedScoreId = 0,
        displayedVariationId = 0,
        headings = [],
        displayMode,
        precision,
        ...doughnutChartProps
    } = props;

    const theme = useTheme();
    const { i18n } = useTranslation();

    const formattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const headingsScore = formattedScore?.headingsScore ?? {};
    const scoreVariations = formattedScore?.scoreVariations ?? {};
    const scoreVariation = scoreVariations[displayedVariationId];

    let scoreVariationValue = 0;
    if (isChapterName(displayMode)) {
        scoreVariationValue = scoreVariation?.chapters[displayMode as ChapterName]?.score ?? 0;
    } else {
        scoreVariationValue = scoreVariation?.score ?? 0;
    }

    const scoreVariationValueStr = formatNumber(scoreVariationValue, i18n.resolvedLanguage, {
        maximumFractionDigits: precision,
    });
    const scoreVariationValueVariant = scoreVariationValueStr.length <= 7 ? "h1" : "h2";

    const scoreVariationFallbackUnit = typeof scoreVariation?.unit === "string" ? scoreVariation?.unit : "";
    const scoreVariationUnit = scoreVariation?.unit_abbreviation ?? scoreVariationFallbackUnit;

    const displayedScoresIds = Object.keys(headingsScore).filter((id: string) =>
        headings.find((heading: Heading) => Number(heading.id) === Number(id)),
    );

    const values = displayedScoresIds.map((key: string) => headingsScore[key].score);

    const labels = displayedScoresIds.map(
        (key: string) => headings.find((heading: Heading) => Number(heading.id) === Number(key))?.title ?? "",
    );

    return (
        <DoughnutChart values={values} labels={labels} legend={{ position: "bottom" }} {...doughnutChartProps}>
            <Stack>
                <Typography variant={scoreVariationValueVariant} fontWeight={800}>
                    {scoreVariationValueStr}
                </Typography>
                <Typography variant="body2" color={theme.custom.grey[1400]}>
                    {scoreVariationUnit}
                </Typography>
            </Stack>
        </DoughnutChart>
    );
};
