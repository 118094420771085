import z from "zod";

import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { Ssq } from "../ssq/useGetOneSsq";

type Options = {
    name?: string;
};
export const useGetAllIngredientReference = (options?: Options) => {
    const { name = "" } = options || {};
    const query = useQuery({
        queryKey: ["ingredient-reference-sure", name],
        queryFn: async () => {
            const nameFilter = encodeURIComponent(name ?? "");

            const url = `/api/ingredient_references?itemsPerPage=400&name=${nameFilter}`;
            const response = await sureApiCall.get(url);
            return response;
        },
        select: (response) => {
            const ingredientReference = response.data["hydra:member"];
            const data = zodParseWithLog(
                ingredientReference,
                IngredientReferenceSchema.array(),
                "allIngredientReference",
            );
            return data;
        },
    });

    return {
        ingredientsReference: query.data,
        ...query,
    };
};

export type IngredientReference = {
    id: number;
    animal?: boolean;
    externalId?: string;
    fresh?: boolean;
    groupFood?: string;
    ingredient?: boolean;
    name: string;
    organic?: boolean;
    source?: string;
    subGroupFood?: string;
    defaultOrigine?: string;
    climateChange?: number;
    ozoneDepletion?: number;
    ionisingRadiation?: number;
    photochemicalOzoneFormation?: number;
    particulateMatter?: number;
    humanToxicityNonCancerOrganics?: number;
    humanToxicityNonCancerInorganics?: number;
    humanToxicityCancerOrganics?: number;
    humanToxicityCancerInorganics?: number;
    acidification?: number;
    eutrophicationFreshWater?: number;
    eutrophicationMarine?: number;
    eutrophicationTerrestrial?: number;
    ecotoxicityFreshwaterOrganics?: number;
    ecotoxicityFreshwaterInorganics?: number;
    landUse?: number;
    waterUse?: number;
    resourceUseFossils?: number;
    resourceUseMineralsMetals?: number;
    bvi?: number;
    createdAt?: string;
    updatedAt?: string;
    plane?: number;
    seasonCode?: number;
    score?: number;
    ssq?: Pick<Ssq, "id">;
};

export const IngredientReferenceSchema: z.ZodSchema<IngredientReference> = z.object({
    id: z.number(),
    name: z.string(),
    animal: z.boolean().optional(),
    externalId: z.string().optional(),
    fresh: z.boolean().optional(),
    groupFood: z.string().optional().optional(),
    ingredient: z.boolean().optional(),
    organic: z.boolean().optional(),
    source: z.string().optional(),
    subGroupFood: z.string().optional(),
    defaultOrigine: z.string().optional(),
    climateChange: z.number().optional(),
    ozoneDepletion: z.number().optional(),
    ionisingRadiation: z.number().optional(),
    photochemicalOzoneFormation: z.number().optional(),
    particulateMatter: z.number().optional(),
    humanToxicityNonCancerOrganics: z.number().optional(),
    humanToxicityNonCancerInorganics: z.number().optional(),
    humanToxicityCancerOrganics: z.number().optional(),
    humanToxicityCancerInorganics: z.number().optional(),
    acidification: z.number().optional(),
    eutrophicationFreshWater: z.number().optional(),
    eutrophicationMarine: z.number().optional(),
    eutrophicationTerrestrial: z.number().optional(),
    ecotoxicityFreshwaterOrganics: z.number().optional(),
    ecotoxicityFreshwaterInorganics: z.number().optional(),
    landUse: z.number().optional(),
    waterUse: z.number().optional(),
    resourceUseFossils: z.number().optional(),
    resourceUseMineralsMetals: z.number().optional(),
    bvi: z.number().optional(),
    createdAt: z.string().optional(),
    updatedAt: z.string().optional(),
    plane: z.number().optional(),
    seasonCode: z.number().optional(),
    score: z.number().optional(),
    ssq: z
        .object({
            id: z.number(),
        })
        .optional(), // Optional object as in original schema
});
