import { createContext, ReactNode, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Post } from "src/api/client-api/post/postSchema";
import { useGetProductPost } from "src/api/client-api/post/useGetProductPost";
import {
    ProductReferenceScores,
    useGetProductReferenceScores,
} from "src/api/client-api/productReferenceScores/useGetProductReferenceScores";
import { Product, useGetOneProduct } from "src/api/client-api/products/useGetOneProduct";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { useGetSomeIngredientReference } from "src/api/sure-api/ingredientReference/useGetSomeIngredientReference";
import { SsqList } from "src/api/sure-api/ssq/useGetAllSsq";
import { Ssq, useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useGetSomeSsq } from "src/api/sure-api/ssq/useGetSomeSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type ProductContextInitialValues = {
    product: Product | undefined;
    productReferenceScores: ProductReferenceScores;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isLoading: boolean;
    isRefetching: boolean;

    ssq: Ssq | undefined;
    isSsqLoading: boolean;

    ingredientReferences: IngredientReference[] | undefined;
    ssqIngredientReferences: SsqList | undefined;
    isSsqLoadingIngredientReferences: boolean;

    post: Post | undefined;
    isPostLoading: boolean;
};

const ProductContext = createContext<ProductContextInitialValues | null>(null);

type ProductContextProviderProps = { children: ReactNode };
export const ProductContextProvider = (props: ProductContextProviderProps) => {
    const { children } = props;
    const { productId } = useParams();

    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const [variations, setVariations] = useState<Record<number, number>>({});
    const { product, isLoading, isRefetching } = useGetOneProduct(productId ?? "A completely bad ID", {
        campaign: currentCampaign?.id,
        variations: variations,
    });

    const { productReferenceScores = [] } = useGetProductReferenceScores(productId ?? "0");

    const ssqId = product?.ssqId;
    const irid = product?.productIngredients
        ?.map((pi) => pi.ingredient.ingredientReferenceId)
        .filter((i) => i !== undefined);
    const { data: ingredientReferences } = useGetSomeIngredientReference(irid);

    const { ssq, isLoading: isSsqLoading } = useGetOneSsq(ssqId);

    const ssqIds = ingredientReferences
        ?.map((ir) => ir.ssq)
        .filter((s) => s !== undefined)
        .map((s) => s.id);
    const { ssq: ssqIngredientReferences, isLoading: isSsqLoadingIngredientReferences } = useGetSomeSsq(ssqIds);

    const { post, isLoading: isPostLoading } = useGetProductPost(
        product?.id,
        selectedCompany?.id ?? null,
        currentCampaign?.id,
    );

    const items: ProductContextInitialValues = {
        product,
        productReferenceScores,
        variations,
        setVariations,
        isLoading,
        isRefetching,
        ssq,
        isSsqLoading,
        post,
        isPostLoading,
        ssqIngredientReferences,
        isSsqLoadingIngredientReferences,
        ingredientReferences,
    };

    return <ProductContext.Provider value={items}>{children}</ProductContext.Provider>;
};

export const useProductContext = () => {
    const context = useContext(ProductContext);

    if (context) {
        return context;
    }

    throw new Error(`useProductContext must be used within an ProductContextProvider`);
};
