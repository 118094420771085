import { Navigate, Route, Routes } from "react-router-dom";
import { useUserNew } from "src/api/client-api/useUserNew.ts";
import { CompaniesService } from "src/services/Companies/CompaniesService";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { BredHome } from "./CustomHome/BredHome";
import { URLHandler } from "src/services/URLHandler";

export const HomeSwitch = () => {
    const { user, isFetched } = useUserNew();
    const clientSwitch = useClientSwitch();

    // Wait for request to be complete;
    if (isFetched === false) return;

    if (user === undefined) {
        return <Navigate to={"/login"} replace />;
    }

    const currentCompany = CompaniesService.selectCurrentCompany(user.companies);

    if (currentCompany === null && user.companies.length === 0) {
        // Logout if user has no company.
        // Maybe we want to redirect to some outside page.
        return <Navigate to={"/logout"} replace />;
    }

    // At component instantiation, if we have no company... take the first company ID;
    const selectedCompany = currentCompany === null ? user.companies[0] : currentCompany;

    const routeElement = clientSwitch({
        defaultOption: undefined, // It would be nice to have a proper default Home.
        bred: <BredHome />,
        foodpilot: (
            <Navigate
                to={
                    ["supplier", "provider"].includes(selectedCompany.type ?? "CannotMatch") ?
                        URLHandler.ingredient.list(selectedCompany.id)
                    :   URLHandler.product.list(selectedCompany.id)
                }
                replace
            />
        ),
        inexweb: <Navigate to={"/inexweb"} replace />,
        collective: <Navigate to={URLHandler.company.ssq(selectedCompany.id)} replace />,
    });
    return (
        <Routes>
            <Route path="" element={routeElement} />
        </Routes>
    );
};
