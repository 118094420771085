import { PostElement } from "src/api/client-api/post/postSchema";
import { Group, Select, Radio, Checkbox, Date, DateRange, Text, Numeric } from "./";
import {
    UpdateProperty,
    PostElementWrite,
    PostWithWriteableElements,
} from "src/api/client-api/property/useUpdateProperty";
import { DeleteProperty } from "src/api/client-api/property/useDeleteProperty";
import { CircularProgress } from "@mui/material";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";

export type FormPropertyProps = {
    property: Property;
    post?: PostWithWriteableElements;
    element?: PostElement | PostElementWrite;
    onUpdate: (data: UpdateProperty) => void;
    onDelete?: (data: DeleteProperty) => void;
    position?: number;
    canEdit?: boolean;
    isSubproperty?: boolean;
    isMultivalued?: boolean;
};

export const FormProperty = (props: FormPropertyProps) => {
    // This is just a preparation for later to be able to give different arguments to those inputs.
    // Because we have LOTS of props. And each inputs use only 1/2 arguments.
    // For instance, the Position is used only for Group and Select.
    const { property, post } = props;

    if (!post) return <CircularProgress />;

    const element =
        props.element ??
        post.elements?.find((element: PostElement | PostElementWrite) => {
            return property.id === element.ssqPropertyId;
        });

    const propertyWithElements = {
        ...props,
        element,
    };

    if (property.children.length > 0) {
        return <Group {...propertyWithElements} />;
    }

    switch (property.field?.type) {
        case "text":
            if (property.unit?.title.toLowerCase() === "string") {
                return <Text {...propertyWithElements} />;
            }

            return <Numeric {...propertyWithElements} />;
        case "group":
            return <Group {...propertyWithElements} />;
        case "select":
            return <Select {...propertyWithElements} />;
        case "radio":
            return <Radio {...propertyWithElements} />;
        case "checkbox":
            return <Checkbox {...propertyWithElements} />;
        case "date":
            return <Date {...propertyWithElements} />;
        case "daterange":
            return <DateRange {...propertyWithElements} />;
        default:
            return <Text {...propertyWithElements} />;
    }
};
