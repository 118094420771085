import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export type FillDefaultMultivalueProperty = {
    postId: number | undefined;
    propertyId: number;
    position: number;
};

export const useFillDefaultsMultivalueProperty = () => {
    return useMutation({
        mutationFn: async (options: FillDefaultMultivalueProperty) => {
            const { postId, propertyId, position } = options;

            return await loggedInApiCall.post(
                `/api/ssq_posts/${postId}/property/${propertyId}/position/${position}/defaults`,
                {},
                {
                    headers: {
                        Accept: "application/json",
                    },
                },
            );
        },
    });
};
