import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetAllIngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { IRI } from "src/types";

type Ingredient = {
    name?: string | null;
    clientId?: string | null;
    organic?: boolean | null;
    ingredientReferenceId?: number | null;
    ingredientLine?: {
        "@id": IRI;
        name: string;
    } | null;
};

export type InfoSectionProps = {
    ingredient: Ingredient;
};
export const InformationSummary = (props: InfoSectionProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { ingredientsReference } = useGetAllIngredientReference();
    const { ingredient } = props;

    const selectedIngredient = ingredientsReference?.find((ing) => ing.id === ingredient.ingredientReferenceId);

    const noValue = "-";
    return (
        <Box
            component="table"
            sx={{
                display: "table",
                width: "100%",
                "&>tr>td": {
                    width: "430px",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                    padding: "20px 0px",
                },
            }}
        >
            <tr>
                <td>
                    <Typography variant="big-bold">{t("Nom de l'ingrédient")}</Typography>
                </td>
                <td>
                    <Typography variant="body-medium">{ingredient.name ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="big-bold">{t("Identifiant unique")}</Typography>
                </td>
                <td>
                    <Typography variant="body-medium">{ingredient.clientId ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="big-bold">{t("Filière")}</Typography>
                </td>
                <td>
                    <Typography variant="body-medium">{ingredient.ingredientLine?.name ?? noValue}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="big-bold">{t("Ingrédient issu de l'agriculture biologique")}</Typography>
                </td>
                <td>
                    <Typography variant="body-medium">{ingredient.organic ? "Oui" : "Non"}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography variant="big-bold">{t("Ingrédient de référence associé")}</Typography>
                </td>
                <td>
                    <Typography variant="body-medium">{selectedIngredient?.name ?? noValue}</Typography>
                </td>
            </tr>
        </Box>
    );
};
