import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { ActivitiesFormData, ActivitiesFormIngredientSupplier } from "./Activities.type";
import { IngredientWrite, IngredientWriteIngredientSupplier } from "src/api/client-api/ingredients/useCreateIngredient";

export class ActivitiesService {
    static getIngredientToActivities = (existingIngredient?: Ingredient): ActivitiesFormData => {
        if (existingIngredient === undefined) return { suppliers: [] };

        const suppliers: ActivitiesFormIngredientSupplier[] =
            existingIngredient?.ingredientSuppliers?.map((supplier) => {
                const averagePrice =
                    !supplier.quantity || supplier.quantity <= 0 ? 0 : (supplier.grossSales ?? 0) / supplier.quantity;

                const activitiesFormSupplier: ActivitiesFormIngredientSupplier = {
                    "@id": supplier["@id"],
                    id: supplier.id,
                    supplier: {
                        name: supplier.supplier.name,
                    },
                    grossSales: supplier.grossSales,
                    quantity: supplier.quantity,
                    averagePrice: averagePrice,
                };

                return activitiesFormSupplier;
            }) ?? [];

        const activities: ActivitiesFormData = { suppliers: suppliers };

        return activities;
    };

    static getActivitiesFormDataToIngredientWrite = (
        existingIngredient: Ingredient,
        activities: ActivitiesFormData,
    ) => {
        const ingredientSupplier: IngredientWriteIngredientSupplier[] = this.getIngredientWriteIngredientSupplier(
            activities.suppliers,
        );

        // [vma]: We put undefined to not update the value on the backend side
        const newIngredient: IngredientWrite = {
            id: existingIngredient.id,
            ingredientSuppliers: ingredientSupplier,
            clientId: undefined,
            ingredientLine: undefined,
            ingredientReferenceId: undefined,
            name: undefined,
            organic: undefined,
        };

        return newIngredient;
    };

    private static getIngredientWriteIngredientSupplier = (
        suppliers: ActivitiesFormIngredientSupplier[],
    ): IngredientWriteIngredientSupplier[] => {
        return suppliers.map((supplier) => {
            return {
                "@id": supplier["@id"],
                id: supplier.id,
                quantity: supplier.quantity,
                grossSales: supplier.grossSales,
                unit: undefined,
                supplier: undefined,
            };
        });
    };
}
