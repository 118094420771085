import { DoughnutChart, FoodsIcon, IconMapping, LinearProgress } from "@foodpilot/foods";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useProductContext } from "../ProductContext";

export const Summary = () => {
    const { product, ssq, post } = useProductContext();

    const totalCompletion = ssq && post ? post.completions.ssq.result : 0;

    return (
        <Stack direction={"row"} gap={4} px={4} py={3.5} sx={{ backgroundColor: "white" }} borderRadius={2}>
            <Stack flex={3} divider={<Divider />} gap={3}>
                <StatsRow percentComplete={totalCompletion} questionnaireCount={1} icon="products" title="Produit" />
                <StatsRow
                    percentComplete={40}
                    questionnaireCount={product?.productIngredients?.length ?? 0}
                    icon="ingredients"
                    title="Ingrédients"
                />
            </Stack>
            <Box flex={1}>
                <DoughnutChart
                    legend={{ position: "bottom" }}
                    values={[60, 40]}
                    labels={["Produit", "Ingrédient"]}
                    colors={["#9de1f3", "#ff9343"]}
                >
                    <Stack>
                        <Typography variant={"h1"} fontWeight={800}>
                            {432}
                        </Typography>
                        <Typography variant="body2" sx={{ color: (theme) => theme.custom.grey[1400] }}>
                            µPts PEF
                        </Typography>
                    </Stack>
                </DoughnutChart>
            </Box>
        </Stack>
    );
};

type StatsRowProps = {
    icon: keyof IconMapping;
    title: string;
    percentComplete: number;
    questionnaireCount: number;
};
const StatsRow = (props: StatsRowProps) => {
    const { icon, percentComplete, questionnaireCount, title } = props;
    return (
        <Stack direction={"row"} alignItems={"center"}>
            <Stack direction={"row"} alignItems={"center"} flex={1} gap={1.5}>
                <Stack
                    sx={{ backgroundColor: (theme) => theme.custom.grey[400], borderRadius: "28px", padding: "8px" }}
                >
                    <FoodsIcon icon={icon} size={3} />
                </Stack>
                <Typography variant="h5">{title}</Typography>
            </Stack>
            <Box flex={1}>
                <Typography variant="body" sx={{ textTransform: "lowercase" }}>
                    {/* TODO: translation w/ count argument */}
                    {questionnaireCount} {t("Questionnaire")}
                    {questionnaireCount > 1 ? "s" : ""}
                </Typography>
            </Box>
            <Box flex={1}>
                <LinearProgress value={percentComplete} />
            </Box>
            <Stack sx={{ backgroundColor: (theme) => theme.custom.grey[400], borderRadius: "28px", padding: "8px" }}>
                <FoodsIcon icon="arrowRight" size={3} />
            </Stack>
        </Stack>
    );
};
