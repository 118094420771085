import InfoIcon from "src/assets/icons/SsqTitleIcon/info.svg?react";
import MilkPot from "src/assets/icons/milk-bottle-small.svg?react";
import PeopleIcon from "src/assets/icons/users-small.svg?react";

import { Page1 } from "./Pages/Page1";
import { Page2 } from "./Pages/Page2";
import { Page3 } from "./Pages/Page3";
import { FoodsFormBase, FoodsPage } from "@foodpilot/foods";

import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormIcon from "src/assets/icons/SsqTitleIcon/form.svg?react";
import { Page4 } from "./Pages/Page4";
import { useCreateSupplier } from "src/api/client-api/suppliers/useCreateSupplier";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { useState } from "react";
import { SupplierFormType } from "./SupplierForm.types";
import { SupplierService } from "../SupplierService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

type SupplierFormProps = {
    existingSupplier?: Supplier;
    startingPageId: string;
};
export const SupplierForm = (props: SupplierFormProps) => {
    const { selectedCompany } = useFoodpilotContext();
    const { existingSupplier, startingPageId } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const createSupplier = useCreateSupplier();

    const formData = SupplierService.getSupplierToCreate(existingSupplier);
    const [supplier, setSupplier] = useState<SupplierFormType>(formData);

    const allPages: FoodsPage[] = [
        {
            title: "Informations générales",
            icon: <InfoIcon />,
            content: <Page1 supplier={supplier} setSupplier={setSupplier} existingSupplier={existingSupplier} />,
        },
        {
            title: "Contributeurs",
            icon: <PeopleIcon />,
            content: <Page2 supplier={supplier} setSupplier={setSupplier} existingSupplier={existingSupplier} />,
        },
        {
            title: "Ingrédients",
            icon: <MilkPot />,
            content: <Page3 supplier={supplier} setSupplier={setSupplier} existingSupplier={existingSupplier} />,
        },
        {
            title: "Résumé des données renseignées",
            icon: <FormIcon />,
            content: <Page4 supplier={supplier} />,
        },
    ];
    const isNew = existingSupplier === undefined;

    // pageId can be an invalid string.
    // If the string is invalid, result is NaN
    // It is not shown in the types.
    const pageId = Number.parseInt(startingPageId);

    if (isNaN(pageId) || selectedCompany === null) {
        return <Typography variant="big-bold">The page you're trying access does not exist</Typography>;
    }

    const subtitle = supplier.clientId ? `${supplier.name || ""} - ${supplier.clientId}` : `${supplier.name || ""}`;

    const supplierListUrl = URLHandler.supplier.list(selectedCompany.id);
    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto", padding: "44px 52px " }}>
            <FoodsFormBase
                showSync={false}
                startingPage={pageId}
                onClose={() => navigate(supplierListUrl)}
                textOptions={{
                    title: isNew ? t("Création d'un nouveau fournisseur") : t("Modifier le fournisseur"),
                    subtitle: subtitle,
                    nextPage: t("Etape suivante"),
                    previousPage: t("previous"),
                    finalPage: t("Sauvegarder"),
                }}
                pages={allPages}
                onValidate={() => {
                    const options = {
                        supplier: SupplierService.supplierFormToSupplierWrite(supplier),
                    };
                    return createSupplier.mutate(options, {
                        onSuccess: () => {
                            navigate(supplierListUrl);
                        },
                    });
                }}
                confirmExit={true}
                confirmOptions={{
                    titleText:
                        isNew ?
                            t("Êtes-vous sûr de vouloir quitter la création du fournisseur ?")
                        :   t("Êtes-vous sûr de vouloir quitter la modification du fournisseur ?"),
                    confirmText: isNew ? t("Supprimer et quitter") : t("Ne pas enregistrer et quitter"),
                    cancelText: isNew ? t("Ne pas quitter la création") : t("Ne pas quitter la modification"),
                    onConfirm: () => navigate(supplierListUrl),
                    onCancel: () => {
                        // Do nothing;
                    },
                }}
            />
        </Box>
    );
};
