import axios, { AxiosError, CreateAxiosDefaults } from "axios";
import { getApiUrl } from "./utils";
import { getFullToken } from "src/utils/token";
import { refreshTokenAxiosHandler } from "./asyncUtils";

const userConfig: CreateAxiosDefaults = {
    baseURL: getApiUrl(),
    withCredentials: true,
    headers: {
        common: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
        },
    },
};

// Kilian's note
// It's not about a "usersMeSession".
// It's about a LOGGEDIN session, that will use the refresh token. And, if fails, throw.
// Which would lead in the end to a logout.

// BUT ITS ALSO ABOUT: A LOGGINGOUT session. Because `logout` musn't do the refreshToken.
// Which would fail. And eventually RESENT to logout.

// This Axios session is only used to know if a user is connected or not.
// This musn't include the refresh token logic.
// Maybe it should include the refresh token logic, BUT...
// Maybe we don't want to throw an error on the login.
export const usersMeSession = axios.create({ ...userConfig, withCredentials: true });
usersMeSession.interceptors.request.use(
    // onFullfill
    (config) => {
        const token = getFullToken();

        if (token.connectedAs) {
            config.headers["X-SWITCH-USER"] = token.connectedAs;
        } else {
            // Ensuring the request NEVER impersonates someone if
            // token doesn't exist.
            delete config.headers["X-SWITCH-USER"];
        }
        return config;
    },
    // onError
);

export const loggedInApiCall = axios.create({ ...userConfig, withCredentials: true });
loggedInApiCall.interceptors.request.use(
    // onFullfill
    (config) => {
        const token = getFullToken();

        if (token.connectedAs) {
            config.headers["X-SWITCH-USER"] = token.connectedAs;
        } else {
            // Ensuring the request NEVER impersonates someone if
            // token doesn't exist.
            delete config.headers["X-SWITCH-USER"];
        }
        return config;
    },
    // onError
);

loggedInApiCall.interceptors.response.use(
    (axiosResponse) => {
        return axiosResponse;
    },
    async (error: Error | AxiosError) => {
        await refreshTokenAxiosHandler(error);

        // Here, we want to send back error to tanstack if unable to do anything with it.
        return Promise.reject(error);
    },
);

export const publicApiCall = axios.create({ ...userConfig, withCredentials: true });
