import { ReactNode, useState } from "react";

import InfoIcon from "src/assets/icons/SsqTitleIcon/info.svg?react";
import BoxIcon from "src/assets/icons/SsqTitleIcon/box.svg?react";
import CutleryIcon from "src/assets/icons/SsqTitleIcon/cutlery.svg?react";
import MarketIcon from "src/assets/icons/SsqTitleIcon/market.svg?react";
import PotIcon from "src/assets/icons/SsqTitleIcon/pot.svg?react";
import ProductionIcon from "src/assets/icons/SsqTitleIcon/production.svg?react";
import BigPot from "src/assets/icons/SsqTitleIcon/big-pot.svg?react";
import BigBox from "src/assets/icons/SsqTitleIcon/big-box.svg?react";
import BigProduction from "src/assets/icons/SsqTitleIcon/big-production.svg?react";
import BigMarket from "src/assets/icons/SsqTitleIcon/big-market.svg?react";
import BigCutlery from "src/assets/icons/SsqTitleIcon/big-cutlery.svg?react";

import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";
import { ButtonTabs } from "@foodpilot/foods";
import { Product } from "src/api/client-api/products/useGetOneProduct";
import { useNavigate } from "react-router-dom";
import { GenericBox } from "src/components/GenericBox";
import { CreateOutlined } from "@mui/icons-material";
import { InfoOutlined } from "@mui/icons-material";
import { ProductInformationSummary } from "src/components/ProductSummary/Information";
import { ProductRecipeSummary } from "src/components/ProductSummary/Recipe";
import { ProductPackagingsSummary } from "src/components/ProductSummary/Packaging";
import { ProductTransformationSummary } from "src/components/ProductSummary/Production";
import { ProductDistributionSummary } from "src/components/ProductSummary/Distribution";
import { ProductConsumptionSummary } from "src/components/ProductSummary/Consumption";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";
import { CiteoBlock } from "src/components/Partnership/CiteoBlock";
import { useProductContext } from "./ProductContext";
import { Loading } from "src/components/utils/Loading";

type ModifyButtonProps = {
    product: Product;
    pageNumber: number;
};
const ModifyButton = (props: ModifyButtonProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { selectedCompany } = useFoodpilotContext();
    const { product, pageNumber } = props;

    const modifyText = t("Modifier");

    if (product.canModify === false) {
        return null;
    }

    return (
        <Button
            variant="primary"
            startIcon={<CreateOutlined />}
            sx={{ marginRight: "24px", borderRadius: "44px" }}
            onClick={() => {
                if (selectedCompany === null) return;
                const url = URLHandler.product.edit(selectedCompany.id, product.id, pageNumber);
                navigate(url);
            }}
        >
            {modifyText}
        </Button>
    );
};

type Page = {
    title: string;
    icon?: ReactNode;
    content: JSX.Element | JSX.Element[];
};

export const ProductInformations = () => {
    const { t } = useTranslation();
    const { product } = useProductContext();
    const [currentPage, setCurrentPage] = useState(0);

    if (!product) {
        return <Loading />;
    }

    const acc: Set<string> = new Set();
    const productSuppliersName =
        product.productIngredients?.reduce((previousValue, currentValue) => {
            const names = currentValue.ingredient?.ingredientSuppliers?.map((i) => i.supplier.name) ?? [];
            names.forEach((name) => previousValue.add(name));

            return previousValue;
        }, acc) ?? new Set();
    const sortedSuppliersName = Array.from(productSuppliersName).toSorted();

    const allPages: Page[] = [
        {
            title: t("Informations générales"),
            icon: <InfoIcon />,
            content: (
                <GenericBox
                    headerIcon={<InfoOutlined />}
                    headerText={t("Informations générales")}
                    controls={<ModifyButton product={product} pageNumber={0} />}
                >
                    <ProductInformationSummary
                        title={product.title}
                        clientId={product.clientId}
                        productLine={product.productLine?.name}
                        productReferenceId={product.productReferenceId}
                        brandName={product.brand?.name}
                        netWeight={product.netWeight}
                        tags={product.tags?.map((t) => t.name) ?? []}
                    />
                </GenericBox>
            ),
        },
        {
            title: t("Recette"),
            icon: <PotIcon />,
            content: (
                <GenericBox
                    headerIcon={<BigPot />}
                    headerText={t("Recette")}
                    controls={<ModifyButton product={product} pageNumber={1} />}
                >
                    <ProductRecipeSummary
                        productIngredients={product.productIngredients ?? []}
                        suppliersName={sortedSuppliersName}
                    />
                </GenericBox>
            ),
        },
        {
            title: t("Emballages"),
            icon: <BoxIcon />,
            content: (
                <Stack display={"flex"} flexDirection={"column"} gap={"24px"}>
                    <GenericBox
                        headerIcon={<BigBox />}
                        headerText={t("Emballages")}
                        controls={<ModifyButton product={product} pageNumber={2} />}
                    >
                        <ProductPackagingsSummary productPackagings={product.productPackagings ?? []} />
                    </GenericBox>
                    <CiteoBlock />
                </Stack>
            ),
        },
        {
            title: t("Production"),
            icon: <ProductionIcon />,
            content: (
                <GenericBox
                    headerIcon={<BigProduction />}
                    headerText={t("Production")}
                    controls={<ModifyButton product={product} pageNumber={3} />}
                >
                    <ProductTransformationSummary productTransformations={product.productTransformations ?? []} />
                </GenericBox>
            ),
        },
        {
            title: t("Distribution"),
            icon: <MarketIcon />,
            content: (
                <GenericBox
                    headerIcon={<BigMarket />}
                    headerText={t("Distribution")}
                    controls={<ModifyButton product={product} pageNumber={4} />}
                >
                    <ProductDistributionSummary distributionChannelId={product.distributionChannelId} />
                </GenericBox>
            ),
        },
        {
            title: t("Consommation"),
            icon: <CutleryIcon />,
            content: (
                <GenericBox
                    headerIcon={<BigCutlery />}
                    headerText={t("Consommation")}
                    controls={<ModifyButton product={product} pageNumber={5} />}
                >
                    <ProductConsumptionSummary consumptionIds={product.consumptionsIds ?? []} />
                </GenericBox>
            ),
        },
    ];

    const choices = allPages.map((p, index) => (
        <Stack key={index} direction="row" alignItems="center" gap="8px">
            {p.icon}
            {p.title}
        </Stack>
    ));

    return (
        <Stack gap="28px">
            <ButtonTabs choices={choices} onClick={(choiceIndex) => setCurrentPage(choiceIndex)} />
            {allPages[currentPage].content}
        </Stack>
    );
};
