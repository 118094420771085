import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export type DeleteProperty = {
    postId: number;
    propertyId: number;
    position?: number;
};
export const useDeleteProperty = () => {
    const query = useMutation({
        mutationFn: async (options: DeleteProperty) => {
            const { postId, propertyId, position = 0 } = options;

            return await loggedInApiCall.delete(
                `/api/ssq_posts/${postId}/property/${propertyId}/position/${position}`,
                {
                    headers: {
                        Accept: "application/json",
                    },
                },
            );
        },
        onSuccess: (result) => {
            // Don't remove this log as it serves the product team
            console.log("Calculation result", result.data);
        },
    });

    return query;
};
