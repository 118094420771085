import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { ReactNode } from "react";

import {
    Dashboard,
    Settings,
    Person,
    LocalShipping,
    Apartment,
    Class,
    EmojiTransportation,
    BarChart,
    DateRange,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import * as React from "react";
import { URLHandler } from "src/services/URLHandler";

export default function SettingsLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const links = useSettingsLinks();

    const getVariant = (route: string) => {
        if (route === URLHandler.settings.list()) {
            return location.pathname === route ? "selected-submenu" : "default-menu";
        }
        return location.pathname.includes(route) ? "selected-submenu" : "default-menu";
    };

    return (
        <Box sx={{ minHeight: "fit-content", display: "flex", flexDirection: "column", height: "100vh" }}>
            <Box
                sx={{
                    height: "fit-content",
                    padding: "1rem",
                    boxShadow: "0 5px 5px linear-gradient(180deg, #484945 0%, #2A2B27 100%)",
                    background: "#484945",
                    display: "flex",
                    alignItems: "center",
                    flexShrink: 0,
                    flexWrap: "wrap",
                    gap: "1rem",
                }}
            >
                {links.map((sl, index) => (
                    <Button key={index} variant={getVariant(sl.to)} startIcon={sl.icon} onClick={() => navigate(sl.to)}>
                        {sl.title}
                    </Button>
                ))}
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: "auto",
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}

type SettingsLink = {
    title: string;
    to: string;
    icon: ReactNode;
};

const useSettingsLinks = (): SettingsLink[] => {
    const { t } = useTranslation();

    return [
        {
            title: t("Tableau de bord"),
            to: URLHandler.settings.list(),
            icon: <Dashboard />,
        },
        {
            title: t("settings.scores"),
            to: URLHandler.settings.scores(),
            icon: <BarChart />,
        },
        {
            title: t("settings.campaign"),
            to: URLHandler.settings.campaigns(),
            icon: <DateRange />,
        },
        {
            title: t("settings.lines"),
            to: URLHandler.settings.productLine.list(),
            icon: <Class />,
        },
        {
            title: t("settings.company"),
            to: URLHandler.settings.companies.list(),
            icon: <Apartment />,
        },
        {
            title: t("settings.companySectors"),
            to: URLHandler.settings.companySectors(),
            icon: <EmojiTransportation />,
        },
        {
            title: t("settings.providers"),
            to: URLHandler.settings.providers.list(),
            icon: <LocalShipping />,
        },
        {
            title: t("settings.users"),
            to: URLHandler.settings.users.list(),
            icon: <Person />,
        },
        {
            title: t("Roles"),
            to: URLHandler.settings.roles.list(),
            icon: <Person />,
        },
        {
            title: "CsrTheme",
            to: URLHandler.settings.csrTheme.list(),
            icon: <Person />,
        },
        {
            title: "Brand",
            to: URLHandler.settings.brands.list(),
            icon: <Settings />,
        },
        {
            title: "Filière",
            to: URLHandler.settings.ingredientLine.list(),
            icon: <Settings />,
        },
        {
            title: t("settings.infos"),
            to: URLHandler.settings.infos(),
            icon: <Settings />,
        },
        {
            title: t("settings.applicationParameter"),
            to: URLHandler.settings.appParams(),
            icon: <Settings />,
        },
    ];
};
