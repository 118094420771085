import { Box, Stack, useTheme } from "@mui/material";
import { ComparisonBlock, DoughnutChart, FoodsToggleButton } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";

export const Tab1Content = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const buttons = [
        { id: 1, label: "Etapes du cycle de vie", onClick: () => navigate("?dimensions=lca[]") },
        { id: 2, label: "Impacts" },
        {
            id: 3,
            label: "Dimension",
        },
    ];

    return (
        <Box>
            <Box display="flex" sx={{ mb: "24px", "& > div": { display: "flex", gap: "16px" } }}>
                <FoodsToggleButton buttons={buttons} initialSelected={buttons[0]} />
            </Box>
            <Stack direction="row" gap="24px">
                <Stack
                    sx={{
                        width: "170px",
                        minWidth: "170px",
                        "& .comparison-block-wrapper": {
                            width: "100%",
                        },
                    }}
                    direction="column"
                    gap="24px"
                >
                    <Box>
                        <ComparisonBlock
                            currentValue={10}
                            comparedTo={{
                                value: 12,
                                unit: "µPts PEF",
                                label: "2022",
                            }}
                            improvement="increase"
                            layout={{
                                direction: "vertical",
                                size: "L",
                            }}
                        />
                    </Box>
                    <ComparisonBlock
                        currentValue={10}
                        comparedTo={{
                            value: 12,
                            unit: "µPts PEF",
                            label: "2022",
                        }}
                        improvement="increase"
                        layout={{
                            direction: "vertical",
                            size: "L",
                        }}
                    />
                </Stack>

                <Box
                    display="flex"
                    sx={{
                        width: "100%",
                        border: `1px solid ${theme.palette.grey[500]}`,
                        borderRadius: "12px",
                        padding: "16px",
                        justifyContent: "center",
                    }}
                >
                    <DoughnutChart
                        values={[10, 19, 9, 19, 5, 16, 18]}
                        labels={["Heading 1", "Heading 2", "Heading 3", "Heading 4", "Heading 5", "Heading 6"]}
                        legend={{
                            position: "right",
                            seeMoreLabel: "See more",
                            actions: [
                                () => {
                                    navigate("https://foodpilot.io/");
                                },
                                undefined,
                                () => {
                                    navigate("https://foodpilot.io/");
                                },
                            ],
                        }}
                    >
                        <Box display="flex" flexDirection="column">
                            <Box key="content-1" fontWeight={800} fontSize={"3rem"}>
                                100,8
                            </Box>
                            <Box sx={{ color: "red" }}>{"eq. gCO2 / col"}</Box>
                        </Box>
                    </DoughnutChart>
                </Box>
            </Stack>
        </Box>
    );
};
