import { Stack, Typography } from "@mui/material";
import { FoodsIllustrations, WhiteBox } from "@foodpilot/foods";

export const UnderConstruction = (): JSX.Element => {
    return (
        <WhiteBox>
            <Stack gap="32px" alignItems="center" padding="32px">
                <FoodsIllustrations illustration="underConstruction" />
                <Typography variant="h3">En cours de construction</Typography>
            </Stack>
        </WhiteBox>
    );
};
