import InfoIcon from "src/assets/icons/SsqTitleIcon/info.svg?react";
import BoxIcon from "src/assets/icons/SsqTitleIcon/box.svg?react";
import CutleryIcon from "src/assets/icons/SsqTitleIcon/cutlery.svg?react";
import FormIcon from "src/assets/icons/SsqTitleIcon/form.svg?react";
import MarketIcon from "src/assets/icons/SsqTitleIcon/market.svg?react";
import PotIcon from "src/assets/icons/SsqTitleIcon/pot.svg?react";
import ProductionIcon from "src/assets/icons/SsqTitleIcon/production.svg?react";

import { Page1 } from "./Pages/Page1";
import { Page2 } from "./Pages/Page2";
import { Page3 } from "./Pages/Page3";
import { Page4 } from "./Pages/Page4";
import { Page5 } from "./Pages/Page5";
import { Page6 } from "./Pages/Page6";
import { Page7 } from "./Pages/Page7";
import { FoodsFormBase } from "@foodpilot/foods";

import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCreateProduct } from "src/api/client-api/products/useCreateProduct";
import { FoodsPage } from "node_modules/@foodpilot/foods/dist/components/Form/FoodsFormBase/FoodsFormBase";
import { Product } from "src/api/client-api/products/useGetOneProduct";
import { useState } from "react";
import { ProductService } from "../ProductService";
import { ProductFormType } from "./ProductForm.types";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

type ProductFormProps = {
    existingProduct?: Product;
    startingPageId: string;
};
export const ProductForm = (props: ProductFormProps) => {
    const { existingProduct, startingPageId } = props;
    const { selectedCompany } = useFoodpilotContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const createProduct = useCreateProduct();

    const formData = ProductService.getProductToCreate(existingProduct);
    const [product, setProduct] = useState<ProductFormType>(formData);

    const allPages: FoodsPage[] = [
        {
            title: t("Informations générales"),
            icon: <InfoIcon />,
            content: <Page1 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Recette"),
            icon: <PotIcon />,
            content: <Page2 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Emballages"),
            icon: <BoxIcon />,
            content: <Page3 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Production"),
            icon: <ProductionIcon />,
            content: <Page4 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Distribution"),
            icon: <MarketIcon />,
            content: <Page5 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Consommation"),
            icon: <CutleryIcon />,
            content: <Page6 product={product} setProduct={setProduct} />,
        },
        {
            title: t("Résumé des données renseignées"),
            icon: <FormIcon />,
            content: <Page7 product={product} />,
        },
    ];
    const isNew = existingProduct === undefined;

    // pageId can be an invalid string.
    // If the string is invalid, result is NaN
    // It is not shown in the types.
    const pageId = Number.parseInt(startingPageId);

    if (isNaN(pageId) || selectedCompany === null) {
        return <Typography variant="big-bold">The page you're trying access does not exist</Typography>;
    }

    const companyIri = selectedCompany["@id"];
    const productListUrl = URLHandler.product.list(selectedCompany.id);
    const subtitle = product.clientId ? `${product.title || ""} - ${product.clientId}` : `${product.title || ""}`;
    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto", padding: "44px 52px " }}>
            <FoodsFormBase
                showSync={false}
                startingPage={pageId}
                onClose={() => navigate(productListUrl)}
                textOptions={{
                    title: isNew ? t("Création d'un nouveau produit") : t("Modifier le produit"),
                    subtitle: subtitle,
                    nextPage: t("Etape suivante"),
                    previousPage: t("previous"),
                    finalPage: t("Sauvegarder"),
                    warningText: t("Attention !"),
                }}
                pages={allPages}
                onValidate={() => {
                    const options = {
                        product:
                            isNew ?
                                ProductService.productFormToProductWrite(product, companyIri)
                            :   ProductService.productFormToProductWrite(product),
                    };
                    return createProduct.mutate(options, {
                        onSuccess: () => {
                            navigate(productListUrl);
                        },
                    });
                }}
                confirmExit={true}
                confirmOptions={{
                    titleText:
                        isNew ?
                            t("Êtes-vous sûr de vouloir quitter la création du produit ?")
                        :   t("Êtes-vous sûr de vouloir quitter la modification du produit ?"),
                    confirmText: isNew ? t("Supprimer et quitter") : t("Ne pas enregistrer et quitter"),
                    cancelText: isNew ? t("Ne pas quitter la création") : t("Ne pas quitter la modification"),
                    onConfirm: () => navigate(productListUrl),
                    onCancel: () => {
                        // Do nothing;
                    },
                }}
            />
        </Box>
    );
};
