import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import {
    CompanySchema,
    CompletionsHeadingsSchema,
    CompletionSchema,
    FormattedScoresSchema,
    PostElementSchema,
} from "./postSchema";
import { z } from "zod";

export const useGetSomeIngredientPosts = (
    ingredientIds: number[] | undefined,
    campaignId?: number,
    companyId?: number,
) => {
    const query = useQuery({
        cacheTime: 0,
        queryKey: ["getIngredientPosts", ingredientIds, campaignId, companyId],
        enabled: ingredientIds !== undefined,
        queryFn: async () => {
            const url = `/api/ssq_posts`;

            return await loggedInApiCall.get(url, {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    ["ingredient.id"]: ingredientIds,
                    ...(campaignId ? { campaignId } : {}),
                    ...(companyId ? { companyId } : {}),
                },
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const posts = response.data;
            const data = zodParseWithLog(posts, IngredientPostSchema.array(), "getSomeIngredientPosts");
            return data;
        },
    });

    return {
        posts: query.data,
        ...query,
    };
};

const IngredientSchema = z.object({
    id: z.number(),
    name: z.string(),
    ingredientReferenceId: z.number().optional(),
    ingredientSuppliers: z.string().array().optional(),
});

const IngredientPostSchema = z.object({
    id: z.number(),
    ssqId: z.number().optional(),
    elements: PostElementSchema.array().optional(),
    formattedScores: FormattedScoresSchema.optional(),
    hiddenElements: z.string().array(),
    company: CompanySchema.optional(),
    updatedAt: z.string(),
    ingredient: IngredientSchema,
    completions: z.object({
        headings: CompletionsHeadingsSchema,
        ssq: CompletionSchema,
    }),
});

export type IngredientPost = z.infer<typeof IngredientPostSchema>;
