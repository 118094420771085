import { useNavigate, useRevalidator, useSearchParams } from "react-router-dom";
import { Box, Container, Button, TextField, FormLabel, MenuItem } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useGetAllSectors } from "src/api/client-api/companySectors/useSupplierSectors";
import { CompanyType, useGetAllSupplier } from "src/api/client-api/suppliers/useGetAllSuppliers";
import { SupplierWrite, useCreateSupplier } from "src/api/client-api/suppliers/useCreateSupplier";
import { CompanySettingsForm, SupplierGridFormZodSchema } from "./CompanySettingsForm";
import { useDelayedSearch } from "src/utils/useDelayedSearch";
import { FoodsSelectServerPagination } from "@foodpilot/foods";
import { useState } from "react";
import { Supplier, SupplierCommon } from "src/api/client-api/suppliers/useGetOneSupplier";
import { URLHandler } from "src/services/URLHandler";

type CompanyCreateProps = {
    type: CompanyType[];
    supplier?: Supplier;
};
export const CompanyCreate = (props: CompanyCreateProps) => {
    const { supplier } = props;
    const navigate = useNavigate();
    const revalidator = useRevalidator();

    const { search, delayedSearch, setSearch } = useDelayedSearch();
    const { suppliers = [] } = useGetAllSupplier({
        type: ["branch", "main", "provider"],
        search: delayedSearch,
    });
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };
    const { companySectors } = useGetAllSectors();

    const createCompany = useCreateSupplier();

    const [searchParams] = useSearchParams();

    const { t } = useTranslation();

    const fromProviders = !!searchParams.get("fromProviders");

    type Schema = z.infer<typeof SupplierGridFormZodSchema>;

    const [parentCompany, setParentCompany] = useState<SupplierCommon | null>(supplier?.parent ?? null);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<Schema>({
        mode: "onChange",
        resolver: zodResolver(SupplierGridFormZodSchema),
    });

    const onSubmitHandler: SubmitHandler<Schema> = (values) => {
        const supplierWrite: SupplierWrite = CompanySettingsForm.getSupplierGridFormToSupplierWrite(values);

        createCompany.mutate(
            { supplier: supplierWrite },
            {
                onSuccess: () => {
                    revalidator.revalidate();
                    navigate(
                        fromProviders ? URLHandler.settings.providers.list() : URLHandler.settings.companies.list(),
                    );
                },
            },
        );
    };

    console.log("Current supplier :", supplier);
    return (
        <Container
            component="main"
            maxWidth={false}
            sx={{
                m: "0",
                p: "5rem !important",
            }}
        >
            <h1>{t("Entreprise")}</h1>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1rem",
                    }}
                >
                    <FormLabel>{t("settings.company.type")}</FormLabel>
                    <Controller
                        name="type"
                        control={control}
                        defaultValue={supplier?.type}
                        render={({ field: { ref, ...field } }) => (
                            <TextField select variant="outlined" inputRef={ref} error={Boolean(errors.type)} {...field}>
                                {props.type.map((companyType, index) => {
                                    return (
                                        <MenuItem key={index} value={companyType}>
                                            {companyType}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="id"
                        control={control}
                        defaultValue={supplier?.id}
                        render={({ field: { ref, ...field } }) => <input type="hidden" {...field}></input>}
                    />
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={supplier?.name}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Nom de l'entreprise")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.name)}
                                helperText={errors.name?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="address1"
                        control={control}
                        defaultValue={supplier?.address1}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Adresse 1")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.address1)}
                                helperText={errors.address1?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="address2"
                        control={control}
                        defaultValue={supplier?.address2}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Adresse 2")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.address2)}
                                helperText={errors.address2?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="city"
                        control={control}
                        defaultValue={supplier?.city}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Ville")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.city)}
                                helperText={errors.city?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="state"
                        control={control}
                        defaultValue={supplier?.state}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Etat")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.state)}
                                helperText={errors.state?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "rows",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "1rem",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="country"
                        control={control}
                        defaultValue={supplier?.country}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Pays")}
                                type="text"
                                variant="outlined"
                                error={Boolean(errors.country)}
                                helperText={errors.country?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        defaultValue={supplier?.phone}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                sx={{ width: "49%" }}
                                label={t("Telephone")}
                                type="tel"
                                variant="outlined"
                                error={Boolean(errors.phone)}
                                helperText={errors.phone?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1rem",
                    }}
                >
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={supplier?.email}
                        render={({ field: { ref, ...field } }) => (
                            <TextField
                                label={t("Email")}
                                type="mail"
                                variant="outlined"
                                error={Boolean(errors.email)}
                                helperText={errors.email?.message}
                                inputRef={ref}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1rem",
                    }}
                >
                    <FormLabel>{t("Activité de l'entreprise")}</FormLabel>
                    <Controller
                        name="companySector"
                        control={control}
                        defaultValue={supplier?.companySector?.["@id"]}
                        render={({ field: { ref, ...field } }) => (
                            <TextField select variant="outlined" inputRef={ref} {...field}>
                                {companySectors &&
                                    companySectors.map((companySector, index) => {
                                        return (
                                            <MenuItem key={index} value={`${companySector["@id"]}`}>
                                                {companySector.name}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        )}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <FormLabel>{t("Entreprise parent")}</FormLabel>
                    <Controller
                        name="parent"
                        control={control}
                        defaultValue={supplier?.parent?.["@id"]}
                        render={({ field: { onChange } }) => {
                            return (
                                <FoodsSelectServerPagination
                                    selectedOption={parentCompany ?? null}
                                    options={suppliers as unknown as SupplierCommon[]}
                                    onChange={(selectedSupplier) => {
                                        onChange(selectedSupplier?.["@id"]);
                                        setParentCompany(selectedSupplier);
                                    }}
                                    getId={(supplier) => supplier?.id}
                                    getName={(supplier) => supplier?.name}
                                    closeOnSelect
                                    textOptions={{
                                        placeholder: t("component.select.placeholder"),
                                        searchPlaceholder: t("component.select.searchPlaceholder"),
                                        emptyOptionsText: t("component.select.noOptions"),
                                        defaultValueText: t("component.select.defaultValue"),
                                        noResult: t("component.select.noResultFound"),
                                    }}
                                    search={search}
                                    setSearch={setSearch}
                                    isSearchFound={isSearchFound}
                                />
                            );
                        }}
                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Button sx={{ marginTop: "1rem", width: "10rem" }} variant="primary" type="submit">
                        {t("Enregistrer")}
                    </Button>
                </Box>
            </form>
        </Container>
    );
};
