import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme } from "@mui/material";
import { formatNumber } from "src/utils/formatting.ts";
import EmptySetIcon from "src/assets/icons/empty-set.svg?react";

export type ScoreProps = {
    value?: number;
    unit: string;
};
export const Score = (props: ScoreProps) => {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const { value, unit } = props;

    return (
        <Stack gap="24px">
            <Stack direction="row" alignItems="baseline" gap="12px">
                <Typography variant="h2">
                    {value ? formatNumber(value, i18n.resolvedLanguage) : <EmptySetIcon />}
                </Typography>
                <Typography component="span" variant="body-medium" color={theme.custom.grey[1400]}>
                    {unit}
                </Typography>
                {/*<Typography component="span" variant="body-medium">pour</Typography>*/}
                {/*<Typography*/}
                {/*    variant="h4"*/}
                {/*>*/}
                {/*    10,9*/}
                {/*</Typography>*/}
                {/*<Typography*/}
                {/*    component="span"*/}
                {/*    variant="body-medium"*/}
                {/*    color={theme.custom.grey[1400]}*/}
                {/*    sx={{ marginLeft: "-6px" }}*/}
                {/*>T.</Typography>*/}
            </Stack>
            {/*<Stack direction="row" gap="8px">*/}
            {/*    <ComparisonIndicator value={12} />*/}
            {/*    <Typography component="span" variant="body-medium">par rapport*/}
            {/*        à <strong>2022</strong> ( <strong>8,91</strong> pour <strong>9,3</strong>*/}
            {/*        <Typography*/}
            {/*            component="span"*/}
            {/*            variant="body-medium"*/}
            {/*            color={theme.custom.grey[1400]}*/}
            {/*        > T.</Typography> )</Typography>*/}
            {/*</Stack>*/}
            {/*<Stack direction="row" gap="8px" marginTop="-12px">*/}
            {/*    <ComparisonIndicator value={12} color="red" direction="down" />*/}
            {/*    <Typography component="span" variant="body-medium">par rapport*/}
            {/*        à <strong>2022</strong> ( <strong>8,91</strong> pour <strong>9,3</strong>*/}
            {/*        <Typography*/}
            {/*            component="span"*/}
            {/*            variant="body-medium"*/}
            {/*            color={theme.custom.grey[1400]}*/}
            {/*        > T.</Typography> )</Typography>*/}
            {/*</Stack>*/}
        </Stack>
    );
};
