import { Button, DottedBox, FoodsIcon, Typography } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";

type NoIngredientsProps = {
    openModal: () => void;
};
export const NoIngredients = (props: NoIngredientsProps) => {
    const { t } = useTranslation();

    return (
        <DottedBox>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "2rem",
                }}
            >
                <Typography variant="big-medium">{"Aucun ingrédient ajouté"}</Typography>
                <Button variant="text" startIcon={<FoodsIcon icon="plus" size={2} />} onClick={props.openModal}>
                    {t("Ajouter ou retirer des ingrédients")}
                </Button>
            </div>
        </DottedBox>
    );
};
