import { HelperBox, NumberField, TextField, useFormBaseContext } from "@foodpilot/foods";
import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductFormBrand, ProductFormProductLine, ProductFormType } from "../../ProductForm.types";
import { useEffect, useRef } from "react";
import { CategoryField } from "./Fields/CategoryField";
import { GammeField } from "./Fields/GammeField";
import { BrandField } from "./Fields/BrandField";
import { ProductRefField } from "./Fields/ProductRefField";

type ProductInformationProps = {
    product: ProductFormType;
    setProduct: (newProduct: ProductFormType) => void;
};
export const ProductInformation = (props: ProductInformationProps) => {
    const { t } = useTranslation();
    const { lockForward, unlockForward } = useFormBaseContext();
    const isWeightModified = useRef(false);

    const { product, setProduct } = props;

    const tiles = product?.tags ? product.tags.map((tag) => tag.name) : [];

    const isTitleValid = (title: string | null): boolean => {
        return title !== null && title.trim().length > 0;
    };
    const isClientIdValid = (clientId: string | null): boolean => {
        return clientId !== null && clientId.trim().length > 0;
    };

    const isNetWeightValid = (netWeight: number | null): boolean => {
        return netWeight !== null && netWeight > 0;
    };

    useEffect(() => {
        const isPageError =
            !isTitleValid(product.title) ||
            !isClientIdValid(product.clientId) ||
            !isNetWeightValid(product.netWeight) ||
            !product.productLine;
        if (isPageError) {
            lockForward(t("form.invalidStep"));
            return;
        }
        unlockForward();
    }, [product.productLine, product.title, product.clientId, product.netWeight]);

    const isWeightError = isWeightModified.current && !isNetWeightValid(product.netWeight);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",
            }}
        >
            <TextField
                title={"Nom du produit"}
                required={true}
                error={!isTitleValid(product.title)}
                placeholder={""}
                value={product.title ?? ""}
                onChange={(e) => {
                    const newName = e.target.value;
                    setProduct({
                        ...product,
                        title: newName,
                    });
                }}
                inputProps={{
                    "data-testid": "product-name-form",
                }}
            />
            <Box>
                <TextField
                    title={"Identifiant unique"}
                    required={true}
                    error={!isClientIdValid(product.clientId)}
                    placeholder={""}
                    value={product.clientId ?? ""}
                    onChange={(e) => {
                        const newClientId = e.target.value;
                        setProduct({
                            ...product,
                            clientId: newClientId,
                        });
                    }}
                    inputProps={{
                        "data-testid": "product-clientId-form",
                    }}
                />
                <HelperBox>
                    <Typography variant="body">
                        {t(
                            "L'identifiant unique correspond à la manière dont vous marquer vos produits dans votre entreprise (GTIN, code-barre, etc...).",
                        )}
                    </Typography>
                </HelperBox>
            </Box>
            <ProductRefField
                productReferenceId={product.productReferenceId}
                setNewProductRef={(newRef: number | null) => {
                    setProduct({
                        ...product,
                        productReferenceId: newRef,
                    });
                }}
            />
            <Box>
                <NumberField
                    title={"Poids net"}
                    required={true}
                    error={isWeightError}
                    value={product.netWeight ?? ""}
                    placeholder={""}
                    unit={"Grammes"}
                    description={""}
                    onChange={(e) => {
                        isWeightModified.current = true;
                        const newWeight = e.target.value;
                        const newProduct: ProductFormType = {
                            ...product,
                            netWeight: newWeight === "" ? null : Number(newWeight),
                        };
                        setProduct(newProduct);
                    }}
                    inputProps={{
                        "data-testid": "product-weight-form",
                    }}
                />
                <HelperBox>
                    <Typography variant="body">{t("product.netWeight.info")}</Typography>
                </HelperBox>
            </Box>
            <BrandField
                brandIRI={product.brand?.["@id"] || null}
                setBrand={(newBrand: ProductFormBrand | null) => {
                    setProduct({
                        ...product,
                        brand: newBrand,
                    });
                }}
            />
            <GammeField
                productLineIRI={product.productLine?.["@id"] ?? null}
                setGamme={(newGamme: ProductFormProductLine | null) => {
                    setProduct({
                        ...product,
                        productLine: newGamme,
                    });
                }}
            />
            <CategoryField tiles={tiles} addAction={setProduct} product={product} />
            <Box>
                <Typography variant="body">{t("form.mandatory-field")}</Typography>
            </Box>
        </div>
    );
};
