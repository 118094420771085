import { GenericAccordion } from "@foodpilot/foods";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import MilkBottle from "src/assets/icons/ingredients.svg?react";
import { Launch } from "@mui/icons-material";
import {
    IngredientReference,
    useGetAllIngredientReference,
} from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";
import { ModifyButton } from "./ModifyButton";
import { SupplierFormType } from "../../SupplierForm.types";

type IngredientSection = {
    supplier: SupplierFormType;
};
export const IngredientSection = (props: IngredientSection) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { supplier } = props;

    const { ingredientsReference } = useGetAllIngredientReference();

    if (ingredientsReference === undefined) {
        return null;
    }

    const refIngIdToRefIng = ingredientsReference.reduce(
        (acc, ing) => {
            acc[ing.id] = ing;
            return acc;
        },
        {} as Record<number, IngredientReference>,
    );

    return (
        <GenericAccordion
            headerIcon={<MilkBottle />}
            headerText="Ingrédients"
            controls={<ModifyButton pageNumber={2} />}
        >
            <Box
                component="table"
                sx={{
                    width: "100%",
                    display: "table",
                    "&>tr>td": {
                        minWidth: "180px",
                        borderBottom: `1px solid ${theme.custom.grey[500]}`,
                        padding: "16px 0px",
                    },
                    "&>tr>th": {
                        minWidth: "180px",
                        borderBottom: `1px solid ${theme.custom.grey[500]}`,
                        padding: "16px 0px",
                        textAlign: "left",
                    },
                }}
            >
                <tr>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Nom")}
                        </Typography>
                    </th>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Référence")}
                        </Typography>
                    </th>
                    <th>
                        <Typography sx={{ color: theme.custom.grey[2000] }} variant="body">
                            {t("Filière")}
                        </Typography>
                    </th>
                </tr>
                {supplier.ingredientSuppliers?.map((ingredientSupplier, index) => {
                    const ingredient = ingredientSupplier.ingredient;

                    const refId = ingredient.ingredientReferenceId || -1; // -1 Means bad ID.
                    return (
                        <tr key={index}>
                            <td>
                                <Button variant="text" endIcon={<Launch />} sx={{ minWidth: "0", marginRight: "10px" }}>
                                    {ingredient.name}
                                </Button>
                            </td>
                            <td>
                                <Typography>{refIngIdToRefIng[refId]?.name ?? "-"}</Typography>
                            </td>
                            <td>
                                <Typography>{ingredient.ingredientLine?.name ?? "-"}</Typography>
                            </td>
                        </tr>
                    );
                })}
            </Box>
        </GenericAccordion>
    );
};
