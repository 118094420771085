import { ChaptersScore, FormattedScore, FormattedScores, Score } from "src/types";
import { Post } from "src/api/client-api/post/postSchema";
import { Heading, Ssq, Property, SsqHeadingSsqProperty } from "src/api/sure-api/ssq/useGetOneSsq";
import { ChapterName, isChapterName } from "src/types";

export const getVisibleHeadings = (ssq: Ssq) => {
    return ssq.headings
        .filter((heading: Heading) => heading.level === 1 && !heading.hidden)
        .sort((headingA: Heading, headingB: Heading) => headingA.position - headingB.position);
};

export const getVisibleProperties = (heading: Heading, hiddenElements: string[]) => {
    const ssqHeadingSsqProperties = heading.ssqHeadingSsqProperties;

    const propertyIdToHiddenMap = ssqHeadingSsqProperties.reduce(
        (map: { [key: number]: boolean | undefined }, ssqHeadingSsqProperty: SsqHeadingSsqProperty) => {
            map[ssqHeadingSsqProperty.ssqProperty.id] = ssqHeadingSsqProperty.hidden;
            return map;
        },
        {} as { [key: number]: boolean | undefined },
    );
    return heading.properties
        .filter(
            (property: Property) =>
                !propertyIdToHiddenMap[property.id] && !hiddenElements.includes(`property-${property.id}`),
        )
        .sort((propertyA: Property, propertyB: Property) => (propertyA.position ?? 0) - (propertyB.position ?? 0));
};

export const getPostFormattedScores = (post?: Post): FormattedScores => {
    return post?.formattedScores ?? {};
};

export const getFormattedScoreByScoreId = (
    formattedScores: FormattedScores,
    scoreId?: number,
): FormattedScore | undefined => {
    return Object.values(formattedScores).find((formattedScore) => formattedScore.chaptersScore?.score_id === scoreId);
};

export const getDefaultDisplayVariationId = (variations: FormattedScore["scoreVariations"]): number => {
    const variationsKeys = Object.keys(variations);
    const defaultDisplayVariationKey = variationsKeys.find((variationId) => variations[variationId].default_display);

    return Number(defaultDisplayVariationKey || variationsKeys[0]) || 0;
};

export const getScoreVariationValue = (
    scoreWithVariations: ChaptersScore,
    score: Score,
    variationId?: number,
): number | undefined => {
    const displayMode = score.displayMode as ChapterName;

    if (!isChapterName(score.displayMode)) {
        return !variationId ? scoreWithVariations?.score : scoreWithVariations?.variations?.[variationId]?.score;
    }

    const chapter = scoreWithVariations.chapters?.[displayMode];

    // For chapters sure calculation returns chapter.result instead of chapter.score
    return chapter?.score ?? chapter?.result;
};
