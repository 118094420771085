import { FoodsIcon, NavbarButtonType } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { COMPANY, INGREDIENT, LIST, PRODUCT, useFoodpilotContext } from "src/context/FoodpilotContext";
import { CompaniesService } from "src/services/Companies/CompaniesService";
import { useTranslation } from "react-i18next";

// Icon missing in the Inexweb
import OpenBookIcon from "../../assets/icons/open-book.svg?react";
import { companySlug, SettingsSlug, URLHandler } from "src/services/URLHandler";

export const useClientButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const clientSwitch = useClientSwitch();

    const { canUser, user, selectedCompany } = useFoodpilotContext();

    const companyIdToRedirect = selectedCompany !== null ? selectedCompany.id : CompaniesService.getCurrentCompanyId();
    if (companyIdToRedirect === null) return [];

    const buttons: NavbarButtonType[] = [];

    // For now, the architecture of this file is mostly about client blocks.
    // Waiting for a real proper feature switching.
    // At least, we're now able to see the common usages.

    // Foodpilot buttons

    const productListUrl = URLHandler.product.list(companyIdToRedirect);
    const productListButton: NavbarButtonType = {
        type: "topLink",
        label: t("Produits"),
        icon: <FoodsIcon size={2} icon="products" />,
        isSelected: location.pathname.includes(productListUrl),
        action: () => navigate(productListUrl),
    };
    if (canUser(LIST, PRODUCT)) {
        const _cs1 = clientSwitch({
            defaultOption: [],
            foodpilot: [productListButton],
        });
        buttons.push(..._cs1);
    }

    const ingredientListUrl = URLHandler.ingredient.list(companyIdToRedirect);
    const ingredientListButton: NavbarButtonType = {
        type: "topLink",
        label: t("Ingrédients"),
        icon: <FoodsIcon size={2} icon="ingredients" />,
        isSelected: location.pathname.includes(ingredientListUrl),
        action: () => navigate(ingredientListUrl),
    };
    if (canUser(LIST, INGREDIENT)) {
        const _cs2 = clientSwitch({
            defaultOption: [],
            foodpilot: [ingredientListButton],
        });
        buttons.push(..._cs2);
    }

    const supplierListUrl = URLHandler.supplier.list(companyIdToRedirect);
    const supplierListButton: NavbarButtonType = {
        type: "topLink",
        label: t("Fournisseurs"),
        icon: <FoodsIcon size={2} icon="supplier" />,
        isSelected: location.pathname.includes(supplierListUrl),
        action: () => navigate(supplierListUrl),
    };
    if (canUser(LIST, COMPANY)) {
        const _cs3 = clientSwitch({
            defaultOption: [],
            foodpilot: [supplierListButton],
        });
        buttons.push(..._cs3);
    }

    // Inexweb buttons

    if (user.trustedUser.roles.includes("ROLE_ADMIN")) {
        const bilanRSEButton: NavbarButtonType = {
            type: "topLink",
            label: "Bilan RSE",
            icon: <OpenBookIcon />,
            isSelected: location.pathname.includes("inexweb"),
            action: () => navigate("inexweb"),
        };
        const _csInexweb = clientSwitch({
            defaultOption: [],
            inexweb: [bilanRSEButton],
        });
        buttons.push(..._csInexweb);
    }

    // Collective buttons
    const diagnosticUrl = URLHandler.company.scores(companyIdToRedirect);
    const trajectoryUrl = URLHandler.actions.list(companyIdToRedirect);
    const isTrajectoryPath = location.pathname.includes(trajectoryUrl);
    const isDiagnosticPath = location.pathname.includes(companySlug) && isTrajectoryPath === false;

    const diagnosticCollectiveButton: NavbarButtonType = {
        type: "topLink",
        label: t("Diagnostic"),
        icon: <FoodsIcon icon="diagnostic" size={3} />,
        isSelected: isDiagnosticPath,
        action: () => navigate(diagnosticUrl),
    };
    const trajectoryCollectiveButton: NavbarButtonType = {
        type: "topLink",
        label: t("Plan d'actions"),
        icon: <FoodsIcon icon="trajectory" size={3} />,
        isSelected: isTrajectoryPath,

        // To access as a dev, comment this line;
        disabled: true,
        action: () => navigate(trajectoryUrl),
    };
    const _csCollective = clientSwitch({
        defaultOption: [],
        collective: [diagnosticCollectiveButton, trajectoryCollectiveButton],
    });
    buttons.push(..._csCollective);

    // BRED buttons

    const bredHomeButton: NavbarButtonType = {
        type: "topLink",
        label: t("Home"),
        icon: <FoodsIcon icon="home" size={3} />,
        isSelected: location.pathname === URLHandler.home(),
        action: () => {
            const url = URLHandler.home();
            navigate(url);
        },
    };
    const diagnosticBREDButton: NavbarButtonType = {
        type: "topLink",
        label: t("Diagnostic"),
        icon: <FoodsIcon icon="diagnostic" size={3} />,
        isSelected: location.pathname.includes(companySlug),
        action: () => {
            const url = URLHandler.company.synthesis(companyIdToRedirect);
            navigate(url);
        },
    };
    const trajectoryButton: NavbarButtonType = {
        type: "topLink",
        label: t("bred.path"),
        icon: <FoodsIcon icon="trajectory" size={3} />,
        isSelected: location.pathname.includes("/path"),
        disabled: true,
        action: () => {},
    };
    const supportButton: NavbarButtonType = {
        type: "topLink",
        label: t("bred.support"),
        icon: <FoodsIcon icon="support" size={3} />,
        isSelected: location.pathname.includes(URLHandler.cms.list()),
        action: () => {
            const url = URLHandler.cms.list();
            navigate(url);
        },
    };
    const _csBRED = clientSwitch({
        defaultOption: [],
        bred: [bredHomeButton, diagnosticBREDButton, trajectoryButton, supportButton],
    });
    buttons.push(..._csBRED);

    // Common buttons

    const documentationButton: NavbarButtonType = {
        type: "topLink",
        label: t("Documentation"),
        icon: <FoodsIcon size={2} icon="documentation" />,
        isSelected: location.pathname.includes(URLHandler.documentation()),
        action: () => navigate(URLHandler.documentation()),
    };
    const _cs5 = clientSwitch({
        defaultOption: [documentationButton],
        bred: [],
        inexweb: [],
    });
    buttons.push(..._cs5);

    if (user.trustedUser.roles.includes("ROLE_ADMIN")) {
        buttons.push({
            type: "topLink",
            label: t("Paramètres globaux"),
            icon: <FoodsIcon size={2} icon="settings" />,
            isSelected: location.pathname.includes(SettingsSlug),
            action: () => navigate(URLHandler.settings.list()),
        });
    }

    return buttons;
};
