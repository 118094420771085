import { Box, Divider, Stack, Typography } from "@mui/material";
import { useProductContext } from "../ProductContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Loading } from "src/components/utils/Loading";
import { DoughnutChart, FoodsIcon, LinearProgress } from "@foodpilot/foods";
import { IngredientPost, useGetSomeIngredientPosts } from "src/api/client-api/post/useGetSomeIngredientPosts";
import { t } from "i18next";
import { useMemo } from "react";

export const Ingredients = () => {
    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const { product } = useProductContext();

    const { posts } = useGetSomeIngredientPosts(
        product?.productIngredients?.map((p) => p.ingredient.id),
        currentCampaign?.id,
        selectedCompany?.id,
    );

    if (!product || !posts) return <Loading />;

    return (
        <Stack gap={3.5} py={3.5} divider={<Divider flexItem />} borderRadius={2} sx={{ backgroundColor: "white" }}>
            <Stack direction={"row"} px={4} gap={2.5} width={"100%"} alignItems="center">
                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ backgroundColor: (theme) => theme.custom.grey[400], borderRadius: "28px", padding: "8px" }}
                    width={"44px"}
                    height={"44px"}
                >
                    <FoodsIcon icon="ingredients" size={3.5} />
                </Stack>
                <Stack>
                    <Stack direction={"row"} alignItems={"baseline"}>
                        <Typography variant="h3">{t("Ingredients")}</Typography>
                        <Typography variant="huge">&nbsp;-&nbsp;7</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1.5} alignItems={"flex-start"}>
                        <Typography variant="body-medium">Complétion totale :</Typography>
                        <Box width="200px">
                            <LinearProgress value={40} />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={"row"} px={4} gap={4} sx={{ backgroundColor: "white" }}>
                <Stack flex={"70%"} divider={<Divider />} gap={3}>
                    {product?.productIngredients?.map((pi, i) => {
                        const post = posts.find((p) => p.ingredient.id === pi.ingredient.id);
                        return <Line key={i} ingredient={pi.ingredient.name} ingredientPost={post} />;
                    })}
                </Stack>

                <Box flex={"30%"}>
                    <DoughnutChart
                        legend={{ position: "bottom" }}
                        values={[60, 40, 0, 0, 0]}
                        labels={product?.productIngredients?.map((pi) => pi.ingredient.name)}
                        colors={["#9de1f3", "#ff9343"]}
                    >
                        <Stack>
                            <Typography variant={"h1"} fontWeight={800}>
                                {432}
                            </Typography>
                            <Typography variant="body2" sx={{ color: (theme) => theme.custom.grey[1400] }}>
                                µPts PEF
                            </Typography>
                        </Stack>
                    </DoughnutChart>
                </Box>
            </Stack>
        </Stack>
    );
};

type LineProps = {
    ingredient: string;
    ingredientPost?: IngredientPost;
};
const Line = (props: LineProps) => {
    const { ingredientPost, ingredient } = props;

    const r = useMemo(
        () => Math.floor(Math.random() * (ingredientPost?.ingredient.ingredientSuppliers?.length ?? 0)),
        [ingredientPost?.ingredient.ingredientSuppliers],
    );
    if (!ingredientPost) {
        return (
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={5}>
                <Typography flex={1} variant="h4">
                    {ingredient}
                </Typography>
                <Stack direction={"row"} justifyContent={"center"} gap={1}>
                    <Typography variant="body">Pas de valeur de référence</Typography>
                    <FoodsIcon icon="information" size={3} />
                    <FoodsIcon icon="openNewTab" size={3} />
                </Stack>
            </Stack>
        );
    }

    const totalCompletion = ingredientPost.completions.ssq.result ?? 0;

    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={5}>
            <Stack flex={2}>
                <Typography flex={1} variant="h4">
                    {ingredient}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                    <FoodsIcon icon="inProgress" size={2} />
                    <Typography variant="small-bold">
                        {r}/{ingredientPost.ingredient.ingredientSuppliers?.length ?? 0} fournisseurs
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} flex={1}>
                <Box flex={1}>
                    <LinearProgress value={totalCompletion} />
                </Box>
                <FoodsIcon icon="openNewTab" size={3} />
            </Stack>
        </Stack>
    );
};
