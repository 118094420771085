import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";

export const useGetIngredientPosts = (ingredientId: number | undefined, campaignId?: number) => {
    const query = useQuery({
        cacheTime: 0,
        queryKey: ["getIngredientPosts", ingredientId, campaignId],
        enabled: ingredientId !== undefined,
        queryFn: async () => {
            let url = `/api/ingredients/${ingredientId}/ssq_posts`;

            if (campaignId) {
                const params = new URLSearchParams({ campaign: campaignId.toString() });
                url = url.concat("?").concat(params.toString());
            }

            return await loggedInApiCall.get(url, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const posts = response.data;
            const data = zodParseWithLog(posts, PostSchema.array().optional(), "getIngredientPosts");
            return data;
        },
    });

    return {
        posts: query.data,
        ...query,
    };
};
